import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import { PropsWithClassName, PropsWithCSSStyle } from '../props';
import css from './boxes.module.scss';

interface TruncateTextBoxProps extends PropsWithChildren<{}>, PropsWithClassName {}

export function TruncateTextBox({ children, className }: TruncateTextBoxProps) {
	return <div className={clsx(css.truncateText, className)}>{children}</div>;
}

//

export interface GridRowBoxProps extends PropsWithChildren<{}>, PropsWithClassName, PropsWithCSSStyle {
	gap?: number;
	spaceBetween?: boolean;
}

export function GridRowBox({ children, className, style, gap, spaceBetween }: GridRowBoxProps) {
	return (
		<div
			className={clsx(css.gridRow, className)}
			style={{ gridGap: gap, justifyContent: spaceBetween ? 'space-between' : undefined, ...style }}
		>
			{children}
		</div>
	);
}
