export const stickerIpfsIds = [
	'QmPNxHsuCpjCoUx8hvanCdzgwQH5CcXcUusvJ2cLyYz8r4',
	'QmUvZc5jmPa5GK7R5KATH5i4n9uUQPh8vPC9k7oB9oMdER',
	'QmdyTMTvKZLg33aRy3Y4ELDu5mn7pvcFbLL8KmSxhM96YR',
	'QmWEBrZzyak2qbDu825q385arxURqKM7GiyffMBTxzidct',
	'QmTRYuhzMYZ1vF8nFNh41K2KN6pF7VQu25BEmGbPoj6Ny8',
	'QmVhfBAsFKnoV4dFtaWWqEAtNTnQmAW3TjJogakvj9VwuU',
	'QmWVkC5on7f6QD4gJTRfjNb6GVYdPa6YJn8JGuU5kDdpZX',
	'QmXnUQwtiTbmedQwZ74LybbHJWMgePTvoRPTUTgCEVkWvT',
	'QmQf26iMvegpgNAiuc2SqDVL2vvwL8Q8wHJajVz3ZzWpZ3',
	'QmQ2cdJmHPtwQEKysejQLfc3JCiwYd7X7CuqLtgHUqNH7U',
	'QmRQk35pqdBj8xo57RzphdpiK5hmf7kGHZ45V2uLAi34UG',
	'QmTovX2GyBwJ5uKKudQ6r5zwjbKr8DEibp7Emodqw19Ta2',
	'QmU9CZfWpAbRNfpU2m515t5mPCiEs3TRH7G1rNdbyK9ZRg',
	'QmU8E9wHZq4TzCAzg4CL9EC7CGD2gBKdH6PbAMwGo2ZqUo',
	'QmRC1MLd2PEb3dRTKNE5z57KPgEfTkvAPX5tfvHjAZiScb',
	'Qme7Cuyk5NezKTXu5gCoNxAXrGv9T6JaUeBGTd1wmPbfpo',
	'QmetxtnQQP71YEn16YkwbrWhN98hjewgSq5vQKQjPpxeE1',
	'QmPrTT1x37dDwDsCPzvAppLgxQhsAExLyr8RjwYyRSE2oV',
	'QmdfDTHJGqFGkds6FaS4NTEqVPUapp33CHChk8Nj5ToCTz',
	'QmTAGJ7HEEFtmqPaUe3bJZwZ8RUKwBDug94AKG5rJ28puT',
	'QmdguqNpb2bUKg6znVSyXtKRaNrtrYVxchaof7xryjarhE',
	'QmW4oWhz6onHJSaWYBTVF68XCgvr8MSrs6m73dJd3tRLTb',
	'QmZVpzzAyKnFXDLpJze7H22p8igxRfuLHCikPA3YMRhLg7',
	'QmSwxecFnFjbe44HJ3pQdLrytrZNcM48XGPbvaTbxWZsCk',
	'QmbYpa9m8QsCuyTG4tPYHtok2Cf7raJrtap9kMsND5Y25x',
];
